
<template>
  <!-- 平台统计 -->
  <div class="">
    <ax-table
      ref="logTable"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :isCard="true"
      :dataSourceApi="api.list"
      @add="openDialog"
    >
    </ax-table>

  </div>
</template>
 
 <script>
const searchForm = [
  {
    label: "关键字",
    type: "",
    model: "location",
    options: {
      placeholder: "请输入姓名/身份证号/联系电话...",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "状态",
    type: "",
    model: "locations",
    options: {
      placeholder: "请选择",
    },
    formItem: {},
    col: { span: 6 },
  },

];
import api from "./api";
import { initGridFormData } from "@/common/tools";
export default {
  components: {  },
  data() {
    return {
      api,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "gridWorkerName",
            ellipsis: true,
            key: 2,
            width: 100,
          },
          {
            title: "身份证号",
            dataIndex: "depName",
            ellipsis: false,
            key: 3,
            width: 80,
          },
          {
            title: "联系方式",
            dataIndex: "gridName",
            ellipsis: true,
            key: 4,
            width: 130,
          },
          {
            title: "心愿时间",
            dataIndex: "gridRole",
            ellipsis: false,
            key: 5,
          },
          {
            title: "状态",
            dataIndex: "gridRoles",
            ellipsis: false,
            key: 6,
          },
        ],
        true,
        {
          maxWidth: 100,
          actions: [
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "detail",
                  type: "#556bff",
                  link: true,
                  // disabled: !(record.status === "1"),
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  type: "#f95a5a",
                  link: true,
                  popDisabled: false, //delete是false是禁用
                  // disabled: !(record.status === "1"),
                };
              },
            },
          ],
        }
      ),
      searchForm: initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 80,
      }),


      visible: false,
    };
  },

  methods: {
    openDialog() {
      this.$refs.lssueDialog.openDialog();
    },
  },

  created() {},

  mounted() {},
};
</script>
 <style lang='less' scoped>
</style>